import React from 'react';

import { colors } from '../../../../styles/atoms/colors';

import CallToAction from '../../../../molecules/CallToAction/CallToAction';
import InlineVideo from '../../../../molecules/InlineVideo/InlineVideo';

import { getYoutubeId } from '../../../../utils/getYoutubeId';

import {
  DetailWrapper,
  PersonContainer,
  VideoWrapper,
} from './AttributionSection.styles';

const AttributionSection = ({ person, reviewLink, video }) => {
  const personName = `${person?.firstName} ${person?.lastName}`;

  return (
    <DetailWrapper>
      <PersonContainer>
        {person && (
          <div className="name-wrapper">
            <div className="name-bold">
              {personName}
              {person?.role && ','}
            </div>
            <div className="role">{person?.role}</div>
          </div>
        )}
        {reviewLink && (
          <CallToAction
            variant="linkColor"
            icon="right"
            link={reviewLink}
            value="View Original Review"
            size="lg"
          />
        )}
      </PersonContainer>
      {video && (
        <VideoWrapper>
          <InlineVideo
            videoId={getYoutubeId(video?.youtubeUrl)}
            videoImage={video?.videoThumbnail?.image}
            iconColor={colors.primary[500]}
            iconBgColor={colors.base.white}
          />
        </VideoWrapper>
      )}
    </DetailWrapper>
  );
};

export default AttributionSection;
